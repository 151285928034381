import { ChakraProvider } from '@chakra-ui/react';
import IntercomProvider from '@providers/intercom';
import * as Sentry from '@sentry/nextjs';
import cookies from '@services/cookies';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CookieConsent from 'react-cookie-consent';
import { hotjar } from 'react-hotjar';
import '../styles/globals.css';
import theme from '../theme';

const queryClient = new QueryClient();
const { getCookie } = cookies();

function HuntlyCandidatoApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    const user = getCookie('client', 'user');
    const userType = getCookie('client', 'userType');

    if (user !== '') {
      Sentry.setUser({
        id: user.id,
        username: `${user.name} ${user.subname}`,
        email: user.email,
        role: userType.type,
        roleId: userType.typeId,
        state: 'User Logged'
      });
    } else {
      Sentry.setUser({
        state: 'User not Logged'
      });
    }

    hotjar.initialize(Number(process.env.NEXT_PUBLIC_HOTJAR_ID) ?? 0, 6);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <IntercomProvider>
          <AnimatePresence mode="wait">
            <Component {...pageProps} key={router.asPath} />
          </AnimatePresence>
          <CookieConsent
            location="bottom"
            buttonText="Aceito"
            overlay
            flipButtons
            cookieName="hh_accept_cookies"
            style={{ background: '#F25252', fontSize: '16px' }}
            buttonStyle={{
              backgroundColor: '#fff',
              color: '#000',
              fontSize: '16px',
              borderRadius: '5px'
            }}
            expires={150}
          >
            <p>
              Nosso site utiliza cookies para seu funcionamento e experiência do
              usuário.
            </p>
          </CookieConsent>
        </IntercomProvider>
      </ChakraProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default HuntlyCandidatoApp;
