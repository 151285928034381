import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: 'Outfit, Poppins, sans-serif',
    heading: 'Outfit, Poppins, sans-serif',
    mono: 'Outfit, Poppins, sans-serif'
  }
});

export default theme;
