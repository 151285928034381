var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://f82d7d498735478e89e40a24f1ac5a6a@o1130301.ingest.sentry.io/6174267',
  tracesSampleRate: 1.0,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: []
    })
  ],
  environment: process.env.NODE_ENV
});
