import CryptoJS from 'crypto-js';

const Cryptography = {
  encrypt: (value = '') =>
    CryptoJS.AES.encrypt(
      typeof value === 'string' ? value : `${value}`,
      process.env.NEXT_PUBLIC_CRYPTO_SECRET || 'huntlysecret'
    ).toString(),
  decrypt: (value = '') =>
    CryptoJS.AES.decrypt(
      value.toString(),
      process.env.NEXT_PUBLIC_CRYPTO_SECRET || 'huntlysecret'
    ).toString(CryptoJS.enc.Utf8)
};

export default Cryptography;
