/* eslint-disable @typescript-eslint/no-shadow */
import { GetServerSidePropsContext } from 'next';
import nookies, { destroyCookie, parseCookies, setCookie } from 'nookies';
import Cryptography from '../cryptography';

const cookies = () => {
  const getCookies = (
    context: unknown,
    ctx: GetServerSidePropsContext | undefined = undefined
  ) => {
    let cookies;
    try {
      if (context === 'client') {
        cookies = parseCookies();
        return cookies;
      }
      if (context === 'server') {
        cookies = nookies.get(ctx);
        return cookies;
      }
      return false;
    } catch (error) {
      return undefined;
    }
  };

  const getCookie = (
    context: unknown,
    key: string | {},
    ctx?: GetServerSidePropsContext | undefined
  ): any => {
    let cookies;
    try {
      if (context === 'client') {
        cookies = parseCookies();
      }
      if (context === 'server') {
        cookies = nookies.get(ctx);
      }

      if (cookies) {
        const rawCookie = Cryptography.decrypt(cookies[`hh_cd_${key}`] ?? '');

        return JSON.parse(rawCookie);
      }
      throw new Error('Erro ao pegar o cookie');
    } catch (error) {
      if (cookies) {
        return Cryptography.decrypt(cookies[`hh_cd_${key}`]);
      }
      return undefined;
    }
  };

  const deleteCookie = (context: unknown, key: string) => {
    try {
      if (context === 'client') {
        destroyCookie(null, `hh_cd_${key}`, {
          path: '/'
        });
        return true;
      }
      if (context === 'server') {
        nookies.destroy(null, `hh_cd_${key}`);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const setNewCookie = (
    context: unknown,
    key: string,
    value: any,
    time = 60 * 60 * 2,
    path = '/'
  ) => {
    // default -> 2 hour
    try {
      if (context === 'client') {
        setCookie(
          undefined,
          `hh_cd_${key}`,
          Cryptography.encrypt(value).toString(),
          {
            maxAge: time,
            path
          }
        );
        return true;
      }
      if (context === 'server') {
        nookies.set(
          undefined,
          `hh_cd_${key}`,
          Cryptography.encrypt(value).toString(),
          {
            maxAge: time,
            path
          }
        );
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  return {
    setNewCookie,
    getCookies,
    getCookie,
    deleteCookie
  };
};

export default cookies;
