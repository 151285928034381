/* eslint-disable vars-on-top */
/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unused-expressions */

import cookies from '@services/cookies';

// Set your APP_ID
export const APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom

// prettier-ignore
export const load = () => {
  (function(){const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{const d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.async=true;s.src=`https://widget.intercom.io/widget/${  APP_ID}`;const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

// Initializes Intercom
export const boot = (options = {}) => {
  const { getCookie } = cookies();

  const user = getCookie('client', 'user');
  const userType = getCookie('client', 'userType');

  window &&
    window.Intercom &&
    window.Intercom('boot', {
      app_id: APP_ID,
      ...options,
      custom_launcher_selector: '#open_intercom_trigger',
      hide_default_launcher: true,
      name: user?.name ?? null,
      email: user?.email ?? null,
      user_id: user?.id ?? null,
      phone: user?.phone ?? null,
      company_name: userType?.name ?? null
    });
};

export const update = () => {
  window && window.Intercom && window.Intercom('update');
};
